import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { colors, H1White, standardWrapper } from "../../../styles/helpers"

const VideoImage = ({ data }) => {
  const imageOneDisplay = getImage(
    data.template.pageTemplateOfficeVisits.imageOne.localFile.childImageSharp
      .gatsbyImageData
  )
  const imageOneAlt = data.template.pageTemplateOfficeVisits.imageOne.altText

  const imageTwoDisplay = getImage(
    data.template.pageTemplateOfficeVisits.imageTwo.localFile.childImageSharp
      .gatsbyImageData
  )
  const imageTwoAlt = data.template.pageTemplateOfficeVisits.imageTwo.altText

  const imageThreeDisplay = getImage(
    data.template.pageTemplateOfficeVisits.imageThree.localFile.childImageSharp
      .gatsbyImageData
  )
  const imageThreeAlt =
    data.template.pageTemplateOfficeVisits.imageThree.altText
  return (
    <SectionStyled>
      <div className="wrapper">
        <div className="video">
          <div className="video-title">
            <h2>Let us show you around...</h2>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: data.template.pageTemplateOfficeVisits.video,
            }}
          />
        </div>
      </div>
      <div className="img-wrap">
        <div className="img-box img-one">
          <GatsbyImage
            image={imageOneDisplay}
            alt={imageOneAlt}
            layout="fullWidth"
            formats={["auto", "webp", "avif"]}
          />
        </div>
        <div className="img-box img-two">
          <GatsbyImage
            image={imageTwoDisplay}
            alt={imageTwoAlt}
            layout="fullWidth"
            formats={["auto", "webp", "avif"]}
          />
        </div>
        <div className="img-box img-three">
          <GatsbyImage
            image={imageThreeDisplay}
            alt={imageThreeAlt}
            layout="fullWidth"
            formats={["auto", "webp", "avif"]}
          />
        </div>
      </div>
    </SectionStyled>
  )
}

const SectionStyled = styled.section`
  .wrapper {
    ${standardWrapper};
  }

  .video {
    position: relative;
    width: 100%;

    .video-title {
      position: absolute;
      top: 5rem;
      right: 0;
      left: 0;
      margin: auto;
      text-align: center;

      h2 {
        ${H1White}
        display: inline-block;
        padding: 0 1.5rem;
        background-color: ${colors.colorPrimary};
      }
    }
  }

  .img-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    .img-box {
      width: 100%;
      margin: 2rem auto;

      @media (min-width: 768px) {
        width: calc((100% / 3) - 4rem);
        margin: auto 2rem;
      }
    }

    .img-one,
    .img-three {
      @media (min-width: 768px) {
        width: calc((100% / 3) - 2rem);
      }
    }

    .img-one {
      @media (min-width: 768px) {
        margin-right: 2rem;
        margin-left: auto;
      }
    }

    .img-three {
      @media (min-width: 768px) {
        margin-right: auto;
        margin-left: 2rem;
      }
    }

    .img-two {
      @media (min-width: 768px) {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }
`

export default VideoImage
