import React, { useEffect } from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { colors, H1White, H2Blue, medWrapper } from "../../../styles/helpers"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

const Steps = ({ data }) => {
  const steps = data.template.pageTemplateOfficeVisits.steps

  useEffect(() => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: "#steps-trigger",
          markers: false,
          start: "top 35%",
          toggleActions: "play none none none",
        },
      })
      .fromTo(
        ".step-item",
        {
          autoAlpha: 0,
          y: 150,
          duration: 2,
        },
        {
          autoAlpha: 1,
          y: 0,
          duration: 2,
          stagger: {
            each: 0.5,
          },
        }
      )
  }, [])

  return (
    <SectionStyled>
      <div id="steps-trigger" className="wrapper">
        <div className="main-title">
          <h2>{data.template.pageTemplateOfficeVisits.mainTitle}</h2>
          <p>{data.template.pageTemplateOfficeVisits.mainSubTitle}</p>
        </div>
        <div className="steps">
          {steps.map((step, index) => {
            const imageDisplay = getImage(
              step.image.localFile.childImageSharp.gatsbyImageData
            )
            const imageAlt = step.image.altText
            return (
              <Step className="step-item" key={index}>
                <div className="step-image">
                  <GatsbyImage
                    image={imageDisplay}
                    alt={imageAlt}
                    layout="fullWidth"
                    formats={["auto", "webp", "avif"]}
                  />
                </div>
                <div className="step-content">
                  <h3>
                    Step {index + 1}: <br />
                    {step.content}
                  </h3>
                </div>
              </Step>
            )
          })}
        </div>
      </div>
    </SectionStyled>
  )
}

const SectionStyled = styled.section`
  padding: 5rem 0;

  .wrapper {
    ${medWrapper};
  }

  .main-title {
    width: 100%;
    text-align: center;

    h2 {
      ${H1White};
      display: inline-block;
      padding: 0 1rem;
      background-color: ${colors.colorPrimary};
    }

    p {
      ${H2Blue};
    }
  }

  .steps {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
`

const Step = styled.div`
  width: 100%;
  margin-bottom: 5rem;
  padding-bottom: 5rem;
  border-bottom: 2.5rem solid ${colors.colorPrimary};
  opacity: 0;

  @media (min-width: 768px) {
    width: calc((100% / 3) - 4rem);
    margin: 2rem;
    padding-bottom: 4.5rem;
  }

  @media (min-width: 1025px) {
    width: calc((100% / 3) - 4rem);
    margin: 2rem;
  }

  .step-content {
    width: 100%;
    margin-top: 2.5rem;

    @media (min-width: 1025px) {
      margin-top: 4rem;
      padding: 0 3rem;
    }

    h3 {
      ${H2Blue};
    }
  }
`

export default Steps
