import React from "react"
import styled from "styled-components"
import { colors, H2White, standardWrapper } from "../../../styles/helpers"

const BlueBlock = ({ data }) => {
  return (
    <DivStyled>
      <div className="wrapper">
        <div className="content">
          <p>{data.template.pageTemplateOfficeVisits.blueBlockContent}</p>
        </div>
      </div>
    </DivStyled>
  )
}

const DivStyled = styled.div`
  background-color: ${colors.colorPrimary};
  padding: 10rem 0;

  .wrapper {
    ${standardWrapper};
  }

  .content {
    width: 100%;
    max-width: 90rem;
    margin: auto;
    text-align: center;

    p {
      ${H2White};
    }
  }
`

export default BlueBlock
