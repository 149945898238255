import React, { useEffect } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { B1White, colors, medWrapper } from "../../../styles/helpers"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

const IconLinks = ({ data }) => {
  const links = data.iconLinks

  useEffect(() => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: "#links-trigger",
          markers: false,
          start: "top 50%",
          toggleActions: "play none none none",
        },
      })
      .fromTo(
        ".link-item",
        {
          autoAlpha: 0,
          y: 150,
        },
        {
          autoAlpha: 1,
          y: 0,
          duration: 1,
          stagger: {
            each: 0.5,
          },
        }
      )
  }, [])

  return (
    <SectionStyled>
      <div id="links-trigger" className="wrapper">
        {links.map((link, index) => {
          const imageDisplay = getImage(
            link.image.localFile.childImageSharp.gatsbyImageData
          )
          const imageAlt = link.image.altText
          return (
            <LinkStyled className="link-item" key={index}>
              <Link to={`/${link.slug}`}>
                <div className="link-image">
                  <GatsbyImage
                    image={imageDisplay}
                    alt={imageAlt}
                    layout="fullWidth"
                    formats={["auto", "webp", "avif"]}
                  />
                </div>
                <div className="link-button">
                  <h2>{link.title}</h2>
                </div>
              </Link>
            </LinkStyled>
          )
        })}
      </div>
    </SectionStyled>
  )
}

const SectionStyled = styled.section`
  padding: 5rem 0;

  .wrapper {
    ${medWrapper};
  }
`

const LinkStyled = styled.div`
  display: block;
  width: 100%;
  margin: 5rem auto;
  transition: all 0.3s ease;
  border-bottom: 2.5rem solid ${colors.colorPrimary};
  text-align: center;
  cursor: pointer;
  opacity: 0;

  @media (min-width: 768px) {
    width: calc((100% / 3) - 4rem);
    margin: 2rem;
  }

  a {
    display: block;
    width: 100%;
    height: 100%;
  }

  h2 {
    ${B1White};
    display: inline-block;
    padding: 1.5rem 3rem;
    border-radius: 0.5rem;
    transition: all 0.3s ease;

    background-color: ${colors.colorPrimary};
  }

  &:hover {
    border-bottom: 2.5rem solid ${colors.colorTertiary};
    h2 {
      background-color: ${colors.colorTertiary};
    }
  }
`

export default IconLinks
