import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"

import Hero from "../components/templates/common/Hero"
import Steps from "../components/templates/officeVisits/Steps"
import CleanContent from "../components/templates/common/CleanContent"
import BlueBlock from "../components/templates/officeVisits/BlueBlock"
import VideoImage from "../components/templates/officeVisits/VideoImage"
import IconLinks from "../components/templates/common/IconLinks"
import ReadyHero from "../components/templates/common/ReadyHero"

const OfficeVisits = props => {
  const {
    hero,
    steps,
    cleanContent,
    blueBlock,
    videoImage,
    iconLinks,
    readyHero,
    seoInfo,
  } = props.data

  const heroData = hero.template.pageTemplateOfficeVisits
  const cleanContentData = cleanContent.template.pageTemplateOfficeVisits
  const readyHeroData = readyHero.template.pageTemplateOfficeVisits
  const iconLinksData = iconLinks.template.pageTemplateOfficeVisits

  return (
    <Layout>
      <Seo
        title={seoInfo.seoFields.swbThemeMetaTitle}
        description={seoInfo.seoFields.swbThemeDescription}
        //metaImg={seoInfo.seoFields.swbThemeImage.localFile.relativePath}
        location={props.location.pathname}
      />
      <Hero data={heroData} />
      <Steps data={steps} />
      <CleanContent data={cleanContentData} />
      <BlueBlock data={blueBlock} />
      <VideoImage data={videoImage} />
      <IconLinks data={iconLinksData} />
      <ReadyHero data={readyHeroData} />
    </Layout>
  )
}

export const officeVisitisTempQuery = graphql`
  query officeVisitisTempPage($id: String!) {
    seoInfo: wpPage(id: { eq: $id }) {
      seoFields {
        swbThemeDescription
        swbThemeMetaTitle
        swbThemeImage {
          localFile {
            relativePath
          }
        }
      }
    }

    hero: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_OfficeVisits {
          pageTemplateOfficeVisits {
            heroImage {
              altText
              sourceUrl
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 2500)
                }
              }
            }

            heroLogo {
              altText
              sourceUrl
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 1500)
                }
              }
            }
          }
        }
      }
    }

    steps: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_OfficeVisits {
          pageTemplateOfficeVisits {
            mainTitle
            mainSubTitle
            steps {
              content
              image {
                altText
                sourceUrl
                localFile {
                  url
                  childImageSharp {
                    gatsbyImageData(width: 1500)
                  }
                }
              }
            }
          }
        }
      }
    }

    cleanContent: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_OfficeVisits {
          pageTemplateOfficeVisits {
            cleanContentTitle
            cleanContentParagraphs
          }
        }
      }
    }

    blueBlock: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_OfficeVisits {
          pageTemplateOfficeVisits {
            blueBlockContent
          }
        }
      }
    }

    videoImage: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_OfficeVisits {
          pageTemplateOfficeVisits {
            video
            imageOne {
              altText
              sourceUrl
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 1500)
                }
              }
            }
            imageTwo {
              altText
              sourceUrl
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 1500)
                }
              }
            }
            imageThree {
              altText
              sourceUrl
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 1500)
                }
              }
            }
          }
        }
      }
    }

    iconLinks: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_OfficeVisits {
          pageTemplateOfficeVisits {
            iconLinks {
              title
              slug
              image {
                altText
                sourceUrl
                localFile {
                  url
                  childImageSharp {
                    gatsbyImageData(width: 1500)
                  }
                }
              }
            }
          }
        }
      }
    }

    readyHero: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_OfficeVisits {
          pageTemplateOfficeVisits {
            readyHeroTitle
            readyHeroContent
            readyHeroButtonText
            readyHeroButtonSlug
            readyHeroImage {
              altText
              sourceUrl
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 2500)
                }
              }
            }
          }
        }
      }
    }
  }
`

export default OfficeVisits
