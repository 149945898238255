import React from "react"
import styled from "styled-components"
import { B2LightBlue, H1Blue, standardWrapper } from "../../../styles/helpers"

import BackgroundOne from "../../Icons/BackgroundOne"

const CleanContent = ({ data }) => {
  return (
    <SectionStyled>
      <div className="wrapper">
        <div className="title">
          <h2>{data.cleanContentTitle}</h2>
        </div>
        <div
          className="paragraphs"
          dangerouslySetInnerHTML={{
            __html: data.cleanContentParagraphs,
          }}
        />
      </div>
      <div className="bg-background">
        <BackgroundOne />
      </div>
    </SectionStyled>
  )
}

const SectionStyled = styled.section`
  position: relative;

  .wrapper {
    ${standardWrapper};
  }

  .title {
    width: 100%;
    max-width: 68rem;
    margin: 2rem auto;
    text-align: center;

    h2 {
      ${H1Blue};
    }
  }
  .paragraphs {
    width: 100%;
    max-width: 68rem;
    margin: 2rem auto;
    text-align: center;

    @media (min-width: 768px) {
      max-width: 50rem;
    }

    @media (min-width: 1025px) {
      max-width: 68rem;
    }

    p {
      ${B2LightBlue};
    }
  }

  .bg-background {
    position: absolute;
    max-width: 45rem;
    bottom: -37.5rem;
    left: -20rem;
    margin: auto;
    z-index: -1;

    @media (min-width: 768px) {
      bottom: -25rem;
      left: -20rem;
      z-index: -1;
    }

    @media (min-width: 1025px) {
      bottom: -20rem;
      left: -20rem;
    }

    @media (min-width: 1200px) {
      bottom: -20rem;
      left: -7rem;
    }
  }
`

export default CleanContent
